import React from "react";
import { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";
import { AppLink } from "../../../../types/site";
import { Box } from "../../core/box";
import { SmartButton } from "../../core/SmartButton/SmartButton";
import { Typography, TypographyProps } from "../../core/Typography/Typography";
import { Section, SectionProps } from "../Section/Section";

const StyledSecondarySmartButton = styled(SmartButton).attrs({
  variant: "secondary",
})`
  border-color: ${({ theme }) => theme.colors.button.primary.inactive.borderColor};

  &:hover {
    color: ${({ theme }) => theme.colors.button.primary.inactive.color};
    background-color: ${({ theme }) => theme.colors.button.primary.inactive.backgroundColor};
  }
`;

export interface PageHeaderSectionProps extends SectionProps {
  title?: string;
  titleProps?: TypographyProps;
  subtitle?: ReactNode;
  cta?: {
    primary: AppLink;
    secondary?: AppLink;
  };
}

export function PageHeaderSection(props: PropsWithChildren<PageHeaderSectionProps>) {
  const { theme, title, titleProps, subtitle, cta, children, ...otherProps } = props;

  return (
    <Section theme={theme} paddingTopSpacing={[17, 19]} paddingBottomSpacing={[8, 12.5]} {...otherProps}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        width={["100%", "100%", 2 / 3]}
        marginX="auto"
      >
        <Typography variant="h1" {...titleProps}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body1" fontSize={[1, 2]} marginTopSpacing={[2.5, 3]}>
            {subtitle}
          </Typography>
        )}
        {cta && (
          <Box marginTopSpacing={[2.5, 6]} display="flex" flexDirection={["column", "row"]} justifyContent="center">
            <SmartButton
              variant="primary"
              size="large"
              marginBottomSpacing={[2, 0]}
              {...cta.primary}
              paddingXSpacing={[0, 0, 4]}
            >
              {cta.primary.label}
            </SmartButton>
            {cta.secondary && (
              <StyledSecondarySmartButton
                size="large"
                marginLeftSpacing={[0, 2.5]}
                {...cta.secondary}
                paddingXSpacing={[0, 0, 4]}
              >
                {cta.secondary.label}
              </StyledSecondarySmartButton>
            )}
          </Box>
        )}
        {children}
      </Box>
    </Section>
  );
}
